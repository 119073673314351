.App {
    /* 使用 CSS 隐藏 input[type="number"] 的增加和减少按钮 */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0; /* 为了确保样式在不同浏览器中生效 */
    } 
  min-height: 100vh;
  // overflow-y: auto;
  background: linear-gradient(to bottom, #e7effd, #f7f8fb, #f3f5fa);
  :global .ant-form-item-control-input {
    height: auto !important;
    min-height: auto;
  }
  .creation_context_creationDAO_link {
    :global .ant-form-item {
      margin-bottom: 5px;
    }
  }
  :global .ant-radio-wrapper-in-form-item {
    color: #5e5e5e !important;
  }
  :global .ant-radio-wrapper-checked {
    color: #2268f3 !important;
  }
  :global .ant-slider-handle {
    box-shadow: 0 0 0 2px #dcdcdc !important;
    border-radius: 50%;
  }
  :global .ant-slider .ant-slider-handle::after {
    box-shadow: none !important;
  }
  :global .ant-slider {
    margin: 0 !important;
  }
  :global .ant-form-item {
    margin-bottom: 30px;
    .ant-form-item-label {
      label {
        height: auto !important;
        font-size: 16px;
      }
      padding-bottom: 6px !important;
      .ant-form-item-required {
        font-size: 16px !important;
        font-weight: normal;
        color: #000000 !important;
        height: auto !important;
        padding-bottom: 0px;
      }
    }
    .ant-form-item-explain-error {
      padding-top: 9px !important;
      font-size: 12px !important;
      font-weight: 350 !important;
    }
    .ant-upload {
      margin: 0 !important;
    }
  }
}
@media screen and (min-width:780px) {
  
  .App {
    height: 100vh;
    overflow-y: auto;
    :global .ant-form-item-control-input {
      height: auto !important;
      min-height: auto;
    }
    .creation_context_creationDAO_link {
      :global .ant-form-item {
        margin-bottom: 5px;
      }
    }

    :global .ant-slider-handle {
      box-shadow: 0 0 0 2px #dcdcdc !important;
    }
    :global .ant-slider .ant-slider-handle::after {
      box-shadow: none !important;
    }
    :global .ant-slider {
      margin: 0 !important;
    }
    :global .ant-form-item {
      margin-bottom: 30px;
      .ant-form-item-label {
        label {
          height: auto !important;
          font-size: 16px;
        }
        padding-bottom: 6px !important;
        .ant-form-item-required {
          font-size: 16px !important;
          height: auto !important;
          padding-bottom: 0px;
        }
      }
      .ant-form-item-explain-error {
        padding-top: 9px !important;
        font-size: 12px !important;
        font-weight: 350 !important;
      }
      .ant-upload {
        margin: 0 !important;
      }
    }
  }
}
