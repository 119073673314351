// 移动端样式
.header {
  padding-top: 20px;
  width: 341px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  &_logo {
    display: flex;
    align-items: center;
    img {
      width: 52.63px;
      height: 53.51px;
      margin-right: 10px;
    }
    span {
      font-size: 22px;
      font-weight: bold;
      color: #000000;
    }
  }
  &_connect {
    display: flex;
    align-items: center;
    img {
      width: 27px;
      height: 27px;
      margin-right: 8px;
    }
    &_dropdown {
      ul {
        padding: 14px 0 !important;
        li {
          padding: 0 !important;
        }
      }
      &_item {
        width: 94.12px;
        height: 28px;
        font-size: 12px;
        text-align: center;
        line-height: 28px;
        color: #403d39;
      }
    }
    &_btn {
      padding: 1px 5px 0;
      text-align: center;
      height: 30.48px;
      border-radius: 6px;
      font-size: 16px !important;
    }
    &_connected {
      width: 57px;
      height: 29px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &_connectedpc {
      display: none;
      width: 120px;
      height: 29px;
      background-color: #2268f3;
    }
    &_modal {
      pointer-events: auto;
      &_context {
        box-sizing: border-box;
        border-radius: 8px;
        padding: 11px 26px 13px 23px;
        background-color: #ffffff;
        width: 341px;
        &_title {
          font-size: 14px;
          color: #000000;
          font-weight: normal;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          img {
            width: 11px;
            height: 11px;
          }
        }
        h4 {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          margin-bottom: 10px;
        }
        &_address {
          box-sizing: border-box;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 291px;
          height: 40px;
          border-radius: 3px;
          background: #f5f6f9;
          border: 1px solid #dcdcdc;
          font-size: 14px;
          font-weight: 500;
          color: #2268f3;
          img {
            width: 10px;
            height: 12px;
          }
          margin-bottom: 12px;
        }
        &_btn {
          width: 291px;
          height: 40px;
          margin-bottom: 9px;
        }
        &_fiboscan {
          font-size: 12px;
          color: #2268f3;
          text-align: center;
        }
      }
    }
  }
}
// pc 端
@media screen and (min-width: 780px) {
  .header {
    position: fixed;
    left: 0;
    top: 0;
    background:  #e7effd;
    box-sizing: border-box;
    padding: 10px 50px 10px;
    width: 100%;
    z-index: 2;
    &_logo {
      img {
        width: 52.63px;
        height: 53.51px;
        margin-right: 10px;
      }
      span {
        font-size: 22px;
      }
    }
    &_connect {
      display: flex;
      align-items: center;
      img {
        width: 27px;
        height: 27px;
        margin-right: 8px;
      }
      &_dropdown {
        ul {
          padding: 14px 0 !important;
          li {
            padding: 0 !important;
          }
        }
        &_item {
          width: 94.12px;
          height: 28px;
          font-size: 12px;
          text-align: center;
          line-height: 28px;
          color: #403d39;
        }
      }
      &_btn {
        padding: 0;
        padding-top: 1px;
        text-align: center;
        width: 140px;
        height: 40px;
        border-radius: 7px;
        font-size: 18px !important;
      }
      &_connected {
        display: none;
      }
      &_connectedpc {
        cursor: pointer;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        width: 160px;
        height: 40px;
        background-color: #2268f3;
        color: #f5f6f9;
        text-align: center;
        line-height: 40px;
        border-radius: 7px;
        padding: 0 20px;
        img {
          margin-right: 5px;
          width: 25px;
          height: 25px;
        }
      }
      &_modal {
        &_context {
          border-radius: 8px;
          padding: 11px 26px 13px 23px;
          width: 500px;
          &_title {
            font-size: 14px;
            margin-bottom: 10px;
            img {
              width: 11px;
              height: 11px;
            }
          }
          h4 {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
          }
          &_address {
            padding: 0 10px;
            width: 100%;
            height: 40px;
            border-radius: 3px;
            border: 1px solid #dcdcdc;
            font-size: 14px;
            font-weight: 500;
            img {
              width: 10px;
              height: 12px;
            }
            margin-bottom: 12px;
          }
          &_btn {
            height: 40px;
            margin-bottom: 9px;
          }
          &_fiboscan {
            font-size: 12px;
          }
        }
      }
    }
  }
}
